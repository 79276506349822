import React, { Component } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "../../../firebase";

import Banners from "./index";

class BannersContainer extends Component {
	constructor() {
		super();

		this.state = {
			preloader: false,
			spotId: null,
			allBanners: [],
			banners: [],
			images: [],
		};
	}

	componentDidMount() {
		this.getImages();
		this.getSpotId();
	}

	componentWillUnmount() {
		const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
		const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		const isNewAdmin = this.props.adminPermission.newAdmin;

		const ref = isNewAdmin
			? `web/settings/city/${keyCity}/brands/${keyBrand}/banners`
			: `web/settings/city/${keyCity}/banners`;

		const bannersUrl = `${isDebug ? "debug" : ""}${ref}`;

		this.props.firebase.bd.ref(bannersUrl).off("value");
	}

	setImages = (images) => this.setState({ images });
	setBanners = (banners) => this.setState({ banners });

	getSpotId = () => {
		let key = this.props.location.pathname.split("/");
		// .find((el) => parseInt(el) >= 0);

		// const spotId = this.props.cities.find((city) => city.key === key).spot_id;
		const spotId = key[key.length - 1];

		this.setState({ spotId });
	};

	getImages = () => {
		this.setState({ preloader: true });

		const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
		const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		const isNewAdmin = this.props.adminPermission.newAdmin;

		const ref = isNewAdmin
			? `web/settings/city/${keyCity}/brands/${keyBrand}/banners`
			: `web/settings/city/${keyCity}/banners`;

		const bannersUrl = `${isDebug ? "debug" : ""}${ref}`;

		this.props.firebase.bd.ref(bannersUrl).on("value", (snapshot) => {
			let banners = snapshot.val();
			banners = banners ? banners : [];

			// if (this.props.cities.length === 1) {
			// 	banners.map((banner, index) => {
			// 		banners[index] = { ...banner, spotId: keyBrand };
			// 	});
			// }

			this.setState({
				banners,
				allBanners: banners,
				images: banners,
				preloader: false,
			});
		});
	};

	deleteBanner = ({ id, data, name }) => {
		console.log("delete banners!", data, id, name);
		const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
		const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		const isNewAdmin = this.props.adminPermission.newAdmin;

		const ref = isNewAdmin
			? `web/settings/city/${keyCity}/brands/${keyBrand}/banners`
			: `web/settings/city/${keyCity}/banners`;

		const bannersUrl = `${isDebug ? "debug" : ""}${ref}`;

		let base_url =
			window.location.hostname == "localhost:3000"
				? "localhost"
				: window.location.hostname;

		// let filtredAllBanners = this.state.allBanners.filter(
		// 	(banner) => banner.spotId !== this.state.spotId
		// );

		let filtredAllBanners = this.state.allBanners;
		let sendData = [...data];

		console.log("delete sendData", sendData);

		console.log(id, name, this.props.login);

		fetch(`https://e-admin.com.ua/photo/deleteBanner.php`, {
			method: "POST",
			body: JSON.stringify({
				id,
				name,
				login: this.props.login,
				folder: "loyalty-system",
			}),
		})
			.then((response) => {
				console.log("response delete", response);
				if (!response.ok && response.status !== 200) {
					throw new Error("Ошибка запроса!");
				} else {
					this.props.firebase.bd
						.ref(bannersUrl)
						.set(sendData)
						.then((res) => {
							this.setImages([]);
							this.getImages();
							setTimeout(() => alert(`Фото ${name} - было удалено!`), 100);
						})
						.catch((error) => {
							console.error(error);
						});
				}
			})
			.catch((error) => {
				console.log("error", error);
			});
	};

	saveText = (text, id) => {
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		console.log("--- saveText --- text id", text, id); // text, id
		try {
			this.props.firebase.bd
				.ref(`${isDebug ? "debug" : ""}/banners/${id}/text`)
				.set(text);
		} catch (err) {
			console.log("Ошибка записи текста!", err);
		}
	};

	addBannerSubmit = (data) => {
		let self = this;

		const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
		const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		const isNewAdmin = this.props.adminPermission.newAdmin;

		const ref = isNewAdmin
			? `web/settings/city/${keyCity}/brands/${keyBrand}/banners`
			: `web/settings/city/${keyCity}/banners`;

		const bannersUrl = `${isDebug ? "debug" : ""}${ref}`;

		const orderLink = this.state.allBanners.length + 1;
		let link;

		if (this.props.adminPermission.isPromotionsBanners) {
			link = `promotions/promotion-${orderLink}`;
		} else {
			link = "";
		}

		const sendBanners = [
			...this.state.allBanners,
			{
				...data,
				order: `${this.state.allBanners.length + 1}`,
				spotId: this.state.spotId,
				bannerLink: link,
			},
		];

		console.log("data to add banner --->>>***", data);

		self.props.firebase.bd
			.ref(bannersUrl)
			.set(sendBanners)
			.then((res) => {
				console.log("success set data", sendBanners);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	// editBannerSubmit = (data, bannerIndex) => {
	// 	let self = this;

	// 	const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
	// 	const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));

	// 	const bannersUrl = `/settings/city/${keyCity}/brands/${keyBrand}/promotions/${bannerIndex}`;
	// 	console.log("data in edit banner submit *** ", data);
	// 	const sendData = {
	// 		description: data.description,
	// 		available: data.available,
	// 		largePhoto: data.largePhoto,
	// 		order: data.order,
	// 		productsId: data.productsId,
	// 		smallPhoto: data.smallPhoto,
	// 		title: data.title,
	// 	};

	// 	self.props.firebase.bd
	// 		.ref(bannersUrl)
	// 		.set(sendData)
	// 		.then((res) => {
	// 			console.log("success set data", sendData);
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 		});
	// };

	sendData = (data) => {
		const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
		const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		const isNewAdmin = this.props.adminPermission.newAdmin;

		const ref = isNewAdmin
			? `web/settings/city/${keyCity}/brands/${keyBrand}/banners`
			: `web/settings/city/${keyCity}/banners`;

		const bannersUrl = `${isDebug ? "debug" : ref}`;

		let self = this;
		let sendData = [];

		console.log("data ---->>", data);

		for (let key in data) {
			console.log(data[key]);
			let {
				description = "",
				available,
				photo,
				order,
				bannerLink,
				// productsId,
				// smallPhoto,
				title = "",
			} = data[key];

			let link;

			const promo = "promotions";

			let isBannerPromo = bannerLink?.split("/")[0] || "";

			if (isBannerPromo === promo) {
				link = `promotions/promotion-${order}`;
			} else {
				link = bannerLink || "";
			}

			if (data[key]) {
				sendData.push({
					description,
					available,
					photo,
					order,
					bannerLink: link,
					// productsId,
					// smallPhoto,
					title,
				});
			}
		}

		self.props.firebase.bd
			.ref(bannersUrl)
			.set(sendData)
			.then((res) => {
				console.log("success set dnd data", sendData);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	render() {
		return (
			<>
				<Banners
					getImages={this.getImages}
					spotId={this.state.spotId}
					onSubmit={this.sendData}
					saveText={this.saveText}
					deleteBanner={this.deleteBanner}
					loadStatus={this.state.preloader}
					banners={this.state.banners}
					allBanners={this.state.allBanners}
					images={this.state.images}
					setImages={this.setImages}
					// banners={this.state.banners}
					setBanners={this.setBanners}
					addBannerSubmit={this.addBannerSubmit}
					// editBannerSubmit={this.editBannerSubmit}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		cities: state.shops.shops,
		login: state.auth.login,
	};
};

export default connect(
	mapStateToProps,
	null
)(withRouter(withFirebase(BannersContainer)));
